import { Query } from '@datorama/akita';
import { SessionState, SessionStore } from './session.store';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SessionQuery extends Query<SessionState> {
	isAuthenticated$ = this.select(state => state.isAuthenticated);
	userInfo$ = this.select(state => state.userInfo);

	constructor(protected store: SessionStore) {
		super(store);
	}
}
