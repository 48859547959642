<ng-container [ngSwitch]="typeUpload">
  <ng-container *ngSwitchCase="'default'" [ngTemplateOutlet]="uploadDefault"></ng-container>
  <ng-container *ngSwitchCase="'uploadAsset'" [ngTemplateOutlet]="uploadAsset"></ng-container>
  <ng-container *ngSwitchCase="'uploadProfile'" [ngTemplateOutlet]="uploadProfile"></ng-container>
</ng-container>

<ng-template #uploadDefault>
  <div class="ng2FileDrop row" ng2FileDrop [uploader]="uploader$">
    <div class="col-6 d-flex">
      <div class="d-flex justify-content-center align-items-center ml-2">
        <i class="fal fa-upload pr-2"></i>
        <span class="span-layout">{{ labelText }}</span>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-center align-items-center flex-column">
      <button type="button" class="btn btn-outline-primary button-upload">
        <span class="span-button-upload">{{ labelButton }}</span>
      </button>
      <div class="download-model">
        <span>
          <a>Baixe aqui</a> o modelo do arquivo.
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadAsset>
  <div *ngIf="uploader$ | async as uploader">
    <div class="ng2FileDrop-asset row" ng2FileDrop [uploader]="uploader">
      <div class="col-12">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <i class="fal fa-upload fa-upload-asset"></i>
          <span class="span-layout-asset">{{ labelText }}</span>
          <button type="button" class="btn btn-outline-primary button-upload-asset" (click)="imageInput.click()">
            <span class="span-button-upload">{{ labelButton }}</span>
            <input #imageInput type="file" id="imageUrl" ng2FileSelect [uploader]="uploader" (change)="onFileSelected($event)" multiple style="display: none;" />
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadProfile>
  <div *ngIf="uploader$ | async as uploader">
    <div class="row" ng2FileDrop [uploader]="uploader">
      <div class="col-12 pl-0">
        <div class="container-img-profile">
          <div class="cicle-icon-profile" [ngStyle]="imgUrl ? {'background-image': 'url(' + imgUrl + ')'} : {}" [ngClass]="{'with-image': imgUrl}">
            <i class="fal fa-user icon-profile" *ngIf="!imgUrl"></i>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <button type="button" class="btn btn-outline-primary button-upload-profile" (click)="imageInput.click()">
            <i class="fal fa-upload mr-1"></i>
            <span class="span-button-upload-profile">{{ labelButton }}</span>
            <input #imageInput type="file" id="imageUrl" ng2FileSelect [uploader]="uploader" multiple  style="display: none;"/>
          </button>
          <button type="button" class="btn btn-outline-primary button-upload-profile-camera">
            <i class="fal fa-camera mr-1 upload-profile-icon"></i>
            <span class="span-button-upload">{{ labelButtonTwo }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
