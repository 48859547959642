import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';

import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { ListLegendComponent } from './list-legend/list-legend.component';
import { Ng2FileDropComponent } from './ng2-file-drop/ng2-file-drop.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

const components = [BtnLoadingComponent, Ng2FileDropComponent, StarRatingComponent, ListLegendComponent];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedPipesModule,
		SharedDirectivesModule,
		PerfectScrollbarModule,
		NgbModule,
		FileUploadModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
    MatSelectModule
	],
	declarations: components,
	exports: components
})
export class SharedComponentsModule { }
