import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MaskDirective } from './mask.directive';
import { InvalidFormScrollDirective } from './invalid-form-scroll.directive';

const directives = [MaskDirective, InvalidFormScrollDirective];

@NgModule({
	imports: [CommonModule, MatInputModule],
	declarations: directives,
	exports: directives
})
export class SharedDirectivesModule {}
