import slugify from 'slugify';
export class Utils {

	static isMobile() {
		return window && window.matchMedia('(max-width: 991px)').matches;
	}

	static ngbDateToDate(ngbDate: { month; day; year }) {
		if (!ngbDate) {
			return null;
		}
		return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
	}
	static dateToNgbDate(date: Date) {
		if (!date) {
			return null;
		}
		date = new Date(date);
		return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
	}
	static scrollToTop(selector: string) {
		if (document) {
			const element = document.querySelector(selector) as HTMLElement;
			element.scrollTop = 0;
		}
	}
	static genId() {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	static downloadCSV(blob) {
		if (blob) {
			const url = window.URL.createObjectURL(blob);
			window.open(url);
		}
	}

	static slug(text: string) {
		return slugify(text, {
			lower: true
		});
	}

	static formatCPForCNPJ(value, type?: string) {
		if(!value){
			return "";
		}
		let valorString: string;

		if (typeof value !== 'string') {
			valorString = value.toString();
		} else {
			valorString = value;
		}
		const valorNumerico = valorString.replace(/\D/g, '');

		if ((valorString.length <= 11) || (type === "F")) {
			valorString = valorNumerico.padStart(11, '0');
			if (valorString.length === 11) {
				return valorString.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
			}
		} else if ((valorString.length <= 14) || (type === "J")) {
			valorString = valorNumerico.padStart(14, '0');
			if (valorString.length === 14) {
				return valorString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
			}
		}

		return valorString;
	}

	static formatZipCode(value: string | number) {
		if (typeof value === 'number') {
			value = value.toString();
		}

		while (value.length < 8) {
			value = '0' + value;
		}

		return value.substring(0, 5) + '-' + value.substring(5);
	}

  static toBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }

  static _isFile(file: File | Blob | string | ArrayBuffer): boolean{
    return file instanceof File || file instanceof Blob;
  }

}
