import { Component, OnInit } from '@angular/core';
import { SessionService } from './store/session/session.service';
import { filter } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
	constructor(private sessionService: SessionService) {}

	ngOnInit(): void {

    window['mapKey'] = environment.mapKey;

		this.sessionService
			.isAuthenticated()
			.pipe(filter(isAuthenticated => isAuthenticated))
			.subscribe(() => {
				this.sessionService.getUser().subscribe();
			});

    this.loadGoogleMapsScript();
	}

  private loadGoogleMapsScript(): void {
		const mapKey = window['mapKey'];
		if (mapKey) {
			const script = document.createElement('script');
			script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&callback=initMap`;
			script.async = true;
			script.defer = true;
			document.head.appendChild(script);
		} else {
			console.error('Validar chave api, Google Maps API key não foi definida');
		}
	}
}
