import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfo, Permission, hasPermissions, hasAdminInovally } from '@app-store/session/session.store';
import { SessionService } from '@app-store/session/session.service';
import { SessionQuery } from '@app-store/session/session.query';
import { SidebarService } from '@app-services/sidebar.service';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-layout-restrict-area-header',
	templateUrl: './layout-restrict-area-header.component.html',
  styleUrls: ['./layout-restrict-area-header.component.scss'],
})
export class LayoutRestrictAreaHeaderComponent implements OnInit {
	isAuthenticated$: Observable<boolean>;
	userInfo$: Observable<UserInfo>;
  userInfo: UserInfo

  loading: boolean = true;

	notifications: any[];

  @Input() filterDisplay = true;

	constructor(
		private sidebarService: SidebarService,
		private sessionService: SessionService,
		private sessionQuery: SessionQuery,
    private location: Location
	) {
		this.notifications = [
			{
				icon: 'i-Speach-Bubble-6',
				title: 'Nova mensagem',
				text: 'Lorem ipsum',
				time: new Date(),
				status: 'primary'
			},
			{
				icon: 'i-Receipt-3',
				title: 'Novo serviço',
				text: 'Lorem ipsum',
				time: new Date('11/11/2018'),
				status: 'success'
			},
			{
				icon: 'i-Empty-Box',
				title: 'Nova peça',
				text: 'Lorem ipsum',
				time: new Date('11/10/2018'),
				status: 'danger'
			}
		];
	}

	ngOnInit() {
		this.isAuthenticated$ = this.sessionQuery.isAuthenticated$;
		this.userInfo$ = this.sessionQuery.userInfo$;
    this.handleGetUserInfo();
	}

	toggelSidebar() {
		this.sidebarService.active = !this.sidebarService.active;
	}

  handleGetUserInfo(): void {
    this.loading = true;
    this.sessionService.getUser().pipe(finalize(() => this.loading = false))
    .subscribe(user => {
      this.userInfo = user;
    }).add(() => {
	  this.loading = false;
	});
  }

	signout() {
		this.sessionService.logout();
	}

}
