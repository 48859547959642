import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd, ActivatedRoute } from '@angular/router';
import { SidebarService } from '@app-services/sidebar.service';
import { Observable, of } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';
import { UserInfo, hasAdminInovally, hasCollectionInovally } from '@app-store/session/session.store';
import { finalize } from 'rxjs/operators';
import { SessionService } from '@app-store/session/session.service';

@Component({
	selector: 'app-layout-restrict-area',
  styleUrls: ['./layout-restrict-area.component.scss'],
	templateUrl: './layout-restrict-area.component.html'
})
export class LayoutRestrictAreaComponent implements OnInit, AfterViewInit {
	moduleLoading: boolean;
	@ViewChild(PerfectScrollbarDirective, { static: true })
	perfectScrollbar: PerfectScrollbarDirective;

  @ViewChild('drawer')
  public drawer: ElementRef;

  public filterStatus$!: Observable<boolean>;
  public filterStatusCampaign$!: Observable<boolean>;
  public filterDebtCampaign$!: Observable<boolean>;

  @ViewChild('draw') drawerOpen: MatDrawer;

  isDrawerOpen = false;

  totalElements: number;

  sidebarType: string = 'general'

  userInfo: UserInfo;

  loading: boolean = true;

  openFilter: boolean = false;
  filterType: string = 'default';

	constructor(
    public sidebarService: SidebarService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
  ) {
  }

	ngOnInit() {
    this.handleGetUserInfo();
		this.router.events.subscribe(event => {
			if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
				this.moduleLoading = true;
			}
			if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
				this.moduleLoading = false;
			}
		});
	}

  ngAfterViewInit(): void {
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }


  openDraw(): void {
    this.drawerOpen.open();
  }

  isLinkActive(link: string[]): boolean {
    const parts = this.router.url.split('/').slice(2);
    return link.some(part => parts.includes(part));
  }

  handleHasPermission(): boolean{
    return hasCollectionInovally(this.userInfo);
  }

  handleGetUserInfo(): void {
    this.loading = true;
    this.sessionService.getUser().pipe(finalize(() => this.loading = false))
    .subscribe(user => {
      this.userInfo = user;
    }).add(() => {
      this.loading = false;
    });
  }

  getFilterStatus(filter: string): void{
    switch (filter) {
      case 'campaign':
        this.filterType = 'campaign'
      case 'debtCampaign':
        this.filterType = 'debtCampaign'
      default:
        this.filterType = 'default'
    }
  }


}
