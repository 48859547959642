import { StoreConfig, Store, akitaConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { UserRoleDTO } from '@app-model/user-role';

export enum Permission {
  FULL = "FULL",
  DASHBOARD = "DASHBOARD",
  DEBT_DOWNLOAD = "DEBT_DOWNLOAD",
  DEBT_SHARE = "DEBT_SHARE",
  CAMPAIGN_VIEW = "CAMPAIGN_VIEW",
  CAMPAIGN_CREATE = "CAMPAIGN_CREATE",
  CAMPAIGN_EDIT = "CAMPAIGN_EDIT",
  CAMPAIGN_EDIT_FULL = "CAMPAIGN_EDIT_FULL",
  CAMPAIGN_SHARE = "CAMPAIGN_SHARE"
}

export class UserInfo {
	id: string;
	name: string;
	cpf: number;
	email: string;
	position: string;
	sector: string;
	phoneNumber: string;
	userRoleId: string;
	role?: UserRoleDTO;
  permissions: string[];
	projectInspector: boolean;
	createdAt: Date;
	updatedAt: Date;
  roleColor: string;
  cityHallId: string;

  hasPermission(permissions: string): boolean {
    if (!this.permissions || this.permissions.length === 0) {
      return false;
    }
    for (const r of this.permissions) {
      if (r === permissions) {
        return true;
      }
    }
    return false;
  }

  hasPermissions(...permissions: string[]): boolean {
    for (const role of permissions) {
      if (this.hasPermission(role)) {
        return true;
      }
    }
    return false;
  }

}

export function hasPermission(user: UserInfo, permission: string): boolean {
  if(!user.permissions || user.permissions.length === 0) {
    return false;
  }
  for (const r of user.permissions){
    if(r === permission){
      return true;
    }
  }
  return false;
}

export function hasPermissions(user: UserInfo, ...permissions: string[]): boolean {
  for (const permission of permissions) {
    if (hasPermission(user, permission)) {
      return true;
    }
  }
  return false;
}

export function hasAdminInovally(user: UserInfo): boolean {
  return hasPermission(user, Permission.FULL);
}

export function hasCollectionInovally(user: UserInfo): boolean {
  return hasPermissions(user, Permission.FULL, Permission.CAMPAIGN_VIEW, Permission.CAMPAIGN_CREATE, Permission.CAMPAIGN_EDIT, Permission.CAMPAIGN_EDIT_FULL, Permission.CAMPAIGN_SHARE);
}

export function hasShareInovallySummary(user: UserInfo): boolean {
  return hasPermissions(user, Permission.FULL, Permission.DEBT_SHARE);
}

export function hasShareDownloadSummary(user: UserInfo): boolean {
  return hasPermissions(user, Permission.FULL, Permission.DEBT_DOWNLOAD);
}

export interface SessionState {
	isAuthenticated: boolean;
	userInfo: UserInfo;
}

export function createInitialState(): SessionState {
	return {
		isAuthenticated: false,
		userInfo: null
	};
}

akitaConfig({
  resettable: true
})

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'sessionState' })
export class SessionStore extends Store<SessionState> {
	constructor() {
		super(createInitialState());
	}

	clearSession(): void {
		this._setState(state => createInitialState());
	}

	updateStatusPlan(status: boolean) {
		this.update(state => {
			return {
				...state,
				userInfo: {
					...state.userInfo,
          hasPermission: state.userInfo.hasPermission,
          hasPermissions: state.userInfo.hasPermissions,
				}
			};
		});
	}
}
