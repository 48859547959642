import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from '@app-shared/components/shared-components.module';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '@app-shared/directives/shared-directives.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogChartComponent } from './dialog-chart/dialog-chart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgChartsModule } from 'ng2-charts';
import { LoadersModule } from '@app-shared/components/loaders/loaders.module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogPreviewComponent } from './dialog-preview/dialog-preview.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogFavoriteFiltersComponent } from './dialog-favorite-filters/dialog-favorite-filters.component';
import { DialogInventorySuccessComponent } from './dialog-inventory-success/dialog-inventory-success.component';
import { DialogNewAssetsComponent } from './dialog-new-assets/dialog-new-assets.component';
import { DialogDeleteAssetsComponent } from './dialog-delete-assets/dialog-delete-assets.component';
import { DialogSuccessDeleteAssetsComponent } from './dialog-success-delete-assets/dialog-success-delete-assets.component';
import { DialogInfoAssetsImgComponent } from './dialog-info-assets-img/dialog-info-assets-img.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DialogSkipStepComponent } from './dialog-skip-step/dialog-skip-step.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogMovementSuccessComponent } from './dialog-movement-success/dialog-movement-success.component';
import { MatDividerModule } from '@angular/material/divider';
import { DialogAssetMissingComponent } from './dialog-asset-missing/dialog-asset-missing.component';
import { DialogFormOrganComponent } from './dialog-form-organ/dialog-form-organ.component';
import { DialogFormSectorComponent } from './dialog-form-sector/dialog-form-sector.component';
import { DialogLocationSuccessComponent } from './dialog-location-success/dialog-location-success.component';
import { DialogFormPersonResponsibleComponent } from './dialog-form-person-responsible/dialog-form-person-responsible.component';
import { DialogFormNewProviderComponent } from './dialog-form-new-provider/dialog-form-new-provider.component';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { DialogFormProductsComponent } from './dialog-form-products/dialog-form-products.component';
import { MatCard, MatCardModule } from '@angular/material/card';
import { DialogCancelMovementComponent } from './dialog-cancel-movement/dialog-cancel-movement.component';
@NgModule({
  declarations: [
    DialogChartComponent,
    DialogPreviewComponent,
    DialogFavoriteFiltersComponent,
    DialogInventorySuccessComponent,
    DialogNewAssetsComponent,
    DialogDeleteAssetsComponent,
    DialogSuccessDeleteAssetsComponent,
    DialogInfoAssetsImgComponent,
    DialogSkipStepComponent,
    DialogMovementSuccessComponent,
    DialogAssetMissingComponent,
    DialogFormOrganComponent,
    DialogFormSectorComponent,
    DialogLocationSuccessComponent,
    DialogFormPersonResponsibleComponent,
    DialogFormNewProviderComponent,
    DialogFormProductsComponent,
    DialogCancelMovementComponent
  ],
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    NgChartsModule,
    LoadersModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSlideToggleModule,
    GoogleMapsModule,
    MatCheckboxModule,
    MatDividerModule,
    // NgxMaskModule.forRoot(),
    TextMaskModule,
    MatCardModule
  ],
  providers: [
    { provide: 'googleMapsApiKey', useValue: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw' }
  ]
})
export class DialogsModule { }
