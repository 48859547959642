import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG,
	HammerGestureConfig,
	BrowserTransferStateModule
} from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { AppStoreModule } from './store/app-store.module';
import { LayoutsModule } from './shared/layouts/layouts.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { Ng2BRPipesModule } from 'ng2-brpipes';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { registerLocaleData, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import localePtBR from '@angular/common/locales/pt';
import { BrowserStateInterceptor } from './interceptors/browserstate.interceptor';
import { NgxMaskModule } from 'ngx-mask';

registerLocaleData(localePtBR);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		BrowserAnimationsModule,
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		AkitaNgRouterStoreModule.forRoot(),
		SharedModule,
		AppStoreModule,
		LayoutsModule,
		HttpClientModule,
		BrowserTransferStateModule
	],
	exports: [Ng2BRPipesModule],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
		{ provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
		{
			provide: APP_BASE_HREF,
			useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
			deps: [PlatformLocation]
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

export function getLocalStorage() {
	return typeof window !== 'undefined' ? window.localStorage : null;
}
