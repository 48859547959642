import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SessionStore, UserInfo } from './session.store';
import { environment } from '@env/environment';
import { tap, catchError, map, take, mergeMap, shareReplay } from 'rxjs/operators';
import { throwError, Observable, of, from, ReplaySubject } from 'rxjs';
import { applyTransaction, resetStores } from '@datorama/akita';
import { Router } from '@angular/router';
import {
	LoginStatus,
	PasswordRegistrationResponse,
	PasswordRegistrationRequest,
	DefaultStatus
} from '@app-model/login';
import { ToastrService } from 'ngx-toastr';
import { AmplifyService } from '@app-services/amplify.service';
import { cloneDeep } from 'lodash'

@Injectable({
	providedIn: 'root'
})
export class SessionService {

	firstLogin = true;
	userInfoLoaded = false;
	private userInfoSubject: ReplaySubject<UserInfo> = new ReplaySubject(1);

	constructor(
		private sessionStore: SessionStore,
		private http: HttpClient,
		private amplifyService: AmplifyService,
		private router: Router,
		private toastr: ToastrService
	) { }

	setLoading(status: boolean) {
		this.sessionStore.setLoading(status);
	}

	checkEmailStatus(email: string): Observable<LoginStatus> {
		this.setLoading(true);
		return this.http.get(`${environment.api}/login/status/${email}`).pipe(
			map((response: LoginStatus) => response),
			tap(() => this.sessionStore.setLoading(false)),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	createUser(email: string) {
		this.sessionStore.setLoading(true);
		return this.http.get(`${environment.api}/login/createUser/${email}`).pipe(
			take(1),
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	resetPassword(userId: string, verifCode: string, password: string): Observable<PasswordRegistrationResponse> {
		this.setLoading(true);
		const request: PasswordRegistrationRequest = {
			userId,
			verifCode,
			password
		};
		return this.http.post(`${environment.api}/login/resetPassword`, request).pipe(
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	verifyUser(userId: string, verifCode: string): Observable<DefaultStatus> {
		return this.http
			.get(`${environment.api}/login/verifyUser/${userId}/${verifCode}`)
			.pipe(map((response: DefaultStatus) => response));
	}

	forgotPassword(email: string) {
		this.setLoading(true);
		return this.http.get(`${environment.api}/login/resetPassword/${email}`).pipe(
			take(1),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	isAuthenticated(): Observable<boolean> {
		return from(this.amplifyService.isAuthenticated()).pipe(
			tap(isAuthenticated => {
				this.sessionStore.update({ isAuthenticated });
			})
		);
	}

	updateIsAuthenticated(isAuthenticated: boolean) {
		applyTransaction(() => {
			this.sessionStore.setLoading(false);
			this.sessionStore.update({ isAuthenticated });
		});
	}

	logout() {
		this.amplifyService.logout();
		this.sessionStore.clearSession();
		this.sessionStore.reset();
		resetStores();
		this.router.navigate(['/login']);
	}

	registerUser(userId: string, verifCode: string, values): Observable<PasswordRegistrationResponse> {
		return this.http.post(`${environment.api}/login/registerNewUser/${userId}/${verifCode}`, values).pipe(
			take(1),
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	getUser(): Observable<UserInfo> {
		if (this.firstLogin && !this.userInfoLoaded) {
		  this.userInfoLoaded = true;
		  this.firstLogin = false;
		  this.sessionStore
			._select(state => state.userInfo)
			.pipe(
			  mergeMap(userInfo => {
				if (userInfo) {
				  return of(userInfo);
				}
				return this.getUserFromBack();
			  }),
			  take(1)
			)
			.subscribe(userInfo => {
			  this.userInfoSubject.next(userInfo);
			}, error => {
			  this.userInfoSubject.error(error);
			});
		}
		
		return this.userInfoSubject.asObservable();
	  }
	

	getUserFromBack(): Observable<UserInfo> {
		let newUserInfo = new UserInfo;
		return this.http.get(`${environment.api}/user/info`).pipe(
			map((userInfo: UserInfo) => {
				newUserInfo = Object.assign(new UserInfo(), userInfo);
				return newUserInfo;
			}),
			tap(userInfo => this.sessionStore.update({ userInfo })),
			shareReplay()
		);
	}

	updateStatusPlan(status) {
		this.sessionStore.updateStatusPlan(status);
	}

	login(username: string, password: string, returnUrl?: string) {
		this.amplifyService.login(username, password).subscribe(
			response => {
				if (response.user) {
					this.updateIsAuthenticated(true);
					this.getUser().subscribe();
					this.router.navigateByUrl(returnUrl ? returnUrl : '/');
				}
			},
			err => {
				this.setLoading(false);
				const { code } = err;
				let message: string;
				if (code === 'auth/wrong-password' || code === 'auth/invalid-login-credentials') {
					message = 'Usuário ou senha inválido';
				} else {
					message = 'Não foi possível realizar o seu login';
				}

				this.toastr.error('', message, {
					closeButton: true,
					progressBar: true,
					positionClass: 'toast-bottom-center',
					extendedTimeOut: 5000,
					timeOut: 10000
				});
			}
		);
	}
}
