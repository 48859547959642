import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';

@NgModule({
	declarations: [LoaderComponent, ProgressCircleComponent],
	imports: [CommonModule],
	exports: [LoaderComponent, ProgressCircleComponent]
})
export class LoadersModule {}
