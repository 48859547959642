import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

export type ButtonColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'link';
export type ButtonSize = 'lg' | 'sm' | 'xs' | 'block' | 'default';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	/**
	 * Inputs
	 */
	@Input() color: ButtonColor;
	@Input() size: ButtonSize;
	@Input() className = '';
	@Input() type = 'submit';
	@Input() loading = false;
	@Input() disabled = false;
	@Input() loadingText;

	/**
	 * Outputs
	 */
	// tslint:disable-next-line: no-output-on-prefix
	@Output() onClick = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	get classList() {
		const classList = ['btn'];
		if (this.color) {
			classList.push(`btn-${this.color}`);
		}

		if (this.size && this.size !== 'default') {
			classList.push(`btn-${this.size}`);
		}

		if (this.className) {
			classList.push(this.className);
		}

		if (this.loading) {
			classList.push('btn-loading');
		}

		return classList.join(' ');
	}
}
