export const environment = {
	appName: 'Inovally',
	home: '/painel/general/inventory/new-inventory',
	production: true,
	api: 'https://inova-patr-api.web.app/api/1.0',
  	mapKey: 'AIzaSyBG402hFieyjE5pUDhh64D3gbjrd-B1lNw',

	passwordRules: {
		minlength: 6,
		minLowercaseCharacterRule: 1,
		minUppercaseCharacterRule: 1,
		minDigitCharacterRule: 1,
		minSpecialCharacterRule: 1
	},

	firebaseConfig: {
		apiKey: "AIzaSyAMmhZQNjAzYIgx0tPtoZgsHzN7T_JXtQ8",
		authDomain: "inovally-data.firebaseapp.com",
		projectId: "inovally-data",
		storageBucket: "inovally-data.appspot.com",
		messagingSenderId: "141649570789",
		appId: "1:141649570789:web:da300c9a9b4d1b5e929d0c"
	},

	iugu_id: '9D81494A225F4215984A5963E784D968',

	title: 'Inovally',
	description: ``,
	image: '',
	keywords: ''
};
