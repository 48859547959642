<header id="main-header">
  <div class="menu-toggle" (click)="toggelSidebar()">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="header-part-left">

    <div class="logo mr-3">
      <div class="icon-home">
        <i class="fal fa-home" [routerLink]="['/']"></i>
      </div>
      <a [routerLink]="['/']">
        <img src="./assets/images/logo-header.svg" height="36px" />
      </a>
    </div>
  </div>

  <div class="header-part-right" *ngIf="isAuthenticated$ | async">
    <div ngbDropdown [placement]="'bottom-right'" class="user col">
      <span id="userDropdown" ngbDropdownToggle alt="">
        <i class="fal fa-user-circle header-icon" role="button"></i>
        <span class="name-user mr-2">Olá, {{ (userInfo$ | async)?.firstName }}</span>
        <i class="fas fa-angle-down"></i>
      </span>
      <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
        <a [routerLink]="['/logout']" (click)="signout()" class="dropdown-item d-flex justify-content-between align-items-center">
          Sair
          <i class="fal fa-sign-out-alt"></i>
        </a>
      </div>
    </div>
  </div>
</header>
