import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { ImgThumbPipe } from './img-thumb.pipe';
import { PersonTypePipe } from '@app-model/debt';

const pipes = [ExcerptPipe, GetValueByKeyPipe, RelativeTimePipe, ImgThumbPipe, PersonTypePipe];

@NgModule({
	imports: [CommonModule],
	declarations: pipes,
	exports: pipes
})
export class SharedPipesModule {}
