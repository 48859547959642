import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidateDirective } from './form-validate/form-validate.directive';
import { MatInputValidateDirective } from './mat-input-validate/mat-input-validate.directive';
import { MatInputValidateMessages } from './mat-input-validate/mat-input-validate-messages.component';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [MatInputValidateDirective, FormValidateDirective, MatInputValidateMessages],
	exports: [MatInputValidateDirective, FormValidateDirective, MatInputModule],
	imports: [CommonModule, MatInputModule],
	entryComponents: [MatInputValidateMessages]
})
export class SharedFormsModule {}
